// This icon file is generated automatically.

import { IconDefinition } from '../types';

const LottoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 32 32',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'g',
        attrs: { 'clip-path': 'url(#clip0_2927_16180)' },
        children: [
          {
            tag: 'path',
            attrs: {
              d: 'M19.87 16.8a7.63 7.63 0 0 0-7.62 7.63c.39 10.1 14.86 10.1 15.25 0 0-4.2-3.42-7.63-7.63-7.63m1.51 8.95c1.25.05 1.25 1.83 0 1.88h-3.01a.94.94 0 0 1-.94-.94c0-1 .93-1.58 1.83-2.15.38-.23 1.07-.67 1.18-.9a.57.57 0 0 0-.57-.54c-.44 0-.56.17-.59.27-.28 1.21-2.03.87-1.84-.36.1-.62.75-1.79 2.43-1.79 1.35 0 2.45 1.1 2.45 2.45 0 .93-.71 1.57-1.47 2.08zM15.2 12.13c0-4.2-3.42-7.63-7.63-7.63-10.1.39-10.1 14.87 0 15.25 4.2 0 7.62-3.42 7.62-7.62m-6.69 2c-.04 1.25-1.83 1.25-1.88 0v-2.07a.94.94 0 0 1-.73-1.6l1-1.01a.94.94 0 0 1 1.61.66zM24.38 0a7.63 7.63 0 0 0-7.62 7.62c.38 10.1 14.86 10.1 15.24 0C32 3.42 28.58 0 24.38 0m2.44 5.98c-.42.64-1.5 2.6-1.5 4.01a.94.94 0 0 1-1.88 0c0-1.24.52-2.6 1-3.59h-1.42c-1.25-.04-1.25-1.83 0-1.87h3.01c.74-.01 1.2.84.79 1.45',
            },
          },
        ],
      },
    ],
  },
  name: 'lotto',
  theme: 'filled',
};

export default LottoFilled;
